import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/case/:caseId",
    name: "CaseView",
    props: true,
    component: () =>
      import(/* webpackChunkName: "CaseView" */ "../views/CaseView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    return {top: 0}
  }
});
router.beforeEach((to, from, next) => {
  if (to.path === '/' && to.hash.startsWith('#/')) {
    next(to.hash.substr(1))
  } else {
    next()
  }
})
export default router;
